html, body, .app {
  min-height: 100vh;
  min-width: 100vw;
}

a {
  text-decoration: inherit;
  color: inherit;
}

.app {
  background: #ffffff;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.app-content {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 2;
  align-items: center;
  justify-content: center;
  
}

.app-header {
}

.app-logo {
  font-size: 2rem;
  font-weight: 200;
  /* font-family: ; */
}

.app-footer {
  min-height: 5rem;
}

.footer-link {
}

.footer-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.4rem;
}
